import React from 'react';
import Layout from '../../../components/layout';
import Vacancy from '../../../components/shared-components/vacancy';

const ReactJs = () => {
  return (
    <Layout>
      <Vacancy
        division="Development"
        vacancy="FrontEnd (React.js) Developer"
        workType="Full time"
        // location="Yerevan, Armenia"
        intro={
          <p>
            Our FrontEnd team is looking for ReactJS Developers. The ideal candidate will be responsible for designing,
            developing, testing, and debugging responsive web applications for the company.
          </p>
        }
        jobResponsibilities={
          <ul>
            <li className="secondary-text">Design, develop, and test UI for responsive web applications</li>
            <li className="secondary-text">Build reusable code and libraries for future use</li>
            <li className="secondary-text">
              Accurately translate user and business needs into functional frontend code.
            </li>
          </ul>
        }
        qualifications={
          <ul>
            <li className="secondary-text">3+ years of work experience in frontend development using React.js</li>
            <li className="secondary-text">Expertise in HTML, CSS, and JavaScript</li>
            <li className="secondary-text">Experience building apps with React Native is a huge plus</li>
            <li className="secondary-text">Knowledge of GraphQL and TypeScript</li>
            <li className="secondary-text">Experience with Sass, Git, Linux</li>
            <li className="secondary-text">At least one open-source project is a huge plus</li>
            <li className="secondary-text">Familiarity using Scrum/Agile development methodologies</li>
          </ul>
        }
      />
    </Layout>
  );
};

export default ReactJs;
